.register-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 800px;
}

.button-register {
    display: flex;
    justify-content: flex-end;
    margin: 10px auto;
    width: 80%;
}

.table-area {
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
}